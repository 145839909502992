import styled from 'styled-components'
import { Text } from '@resultadosdigitais/tangram-components'
import {
  HeartSolid,
  People,
  GrinHearts,
} from '@resultadosdigitais/tangram-react-icons'
import { useTranslation } from 'react-i18next'
import CTAButton from './Promotion/CTAButton'

const Root = styled.div`
  width: 100%;
  padding: var(--size-spacing-06) var(--size-spacing-11);
  margin-top: var(--size-spacing-04);
  background: #00819a;

  p {
    color: var(--color-white);
    text-align: center;
  }

  svg {
    fill: var(--color-white);
  }
`
const Stack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: ${props =>
      props.spacing ? props.spacing : 'var(--size-spacing-06)'};
  }
`

const PromotionItem = styled(Stack)`
  max-width: 32ch;
  p {
    text-align: center;
  }
`

const Cluster = styled.div`
  overflow: hidden;

  > * {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: calc(${props => props.spacing} / 2 * -1);
    flex-wrap: wrap;
  }

  > * > * {
    margin: calc(${props => props.spacing} / 2);
  }
`

export default function ({ email, ...otherProps }) {
  const { t } = useTranslation()

  return (
    <Root {...otherProps}>
      <Stack spacing="var(--size-spacing-06)">
        <Text token={Text.tokens.TEXT_XL_BOLD}>
          {t('Que tal turbinar ainda mais seu Marketing?')}
        </Text>
        <Text token={Text.tokens.TEXT_MD_BOLD}>
          {t('Venha crescer com o RD Station Marketing ++')}
        </Text>
        <Cluster spacing="var(--size-spacing-08)">
          <div>
            <PromotionItem spacing="var(--size-spacing-04)">
              <People
                size={People.sizes.lg}
                title="Leads"
                style={{ fill: 'var(--color-primary-50)' }}
              />
              <Text
                token={Text.tokens.TEXT_MD_EXTRABOLD}
                style={{ textTransform: 'uppercase' }}
              >
                {t('Gestão de Leads')}
              </Text>
              <Text
                token={Text.tokens.TEXT_MD_REGULAR}
                style={{ textAlign: 'center' }}
              >
                {t(
                  'Gerencie as pessoas (Leads) que entrarem em contato com você ++',
                )}
              </Text>
            </PromotionItem>
            <PromotionItem spacing="var(--size-spacing-04)">
              <GrinHearts
                size={GrinHearts.sizes.lg}
                title="Leads"
                style={{ fill: 'var(--color-primary-50)' }}
              />
              <Text
                token={Text.tokens.TEXT_MD_EXTRABOLD}
                style={{ textTransform: 'uppercase' }}
              >
                {t('Landing Pages')}
              </Text>
              <Text
                token={Text.tokens.TEXT_MD_REGULAR}
                style={{ textAlign: 'center' }}
              >
                {t('Crie Landing Pages personalizadas e ampliar as formas ++')}
              </Text>
            </PromotionItem>
            <PromotionItem spacing="var(--size-spacing-04)">
              <HeartSolid
                size={HeartSolid.sizes.lg}
                title="Leads"
                style={{ fill: 'var(--color-primary-50)' }}
              />
              <Text
                token={Text.tokens.TEXT_MD_EXTRABOLD}
                style={{ textTransform: 'uppercase' }}
              >
                {t('Email Marketing')}
              </Text>
              <Text
                token={Text.tokens.TEXT_MD_REGULAR}
                style={{ textAlign: 'center' }}
              >
                {t('Construa um relacionamento com estes Leads através ++')}
              </Text>
            </PromotionItem>
          </div>
        </Cluster>
        <CTAButton email={email} />
      </Stack>
    </Root>
  )
}
