const actions = {
  FORM_SUBMITTED: 'FORM_SUBMITTED',
  FORM_SUBMISSION_SUCCESS: 'FORM_SUBMISSION_SUCCESS',
}
const initialState = {
  step: 'form',
  loading: false,
  code: null,
  name: null,
  email: null,
  message: null,
  phone: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.FORM_SUBMITTED:
      return {
        ...state,
        loading: true,
        name: action.payload.name,
        email: action.payload.email,
        message: action.payload.message,
        phone: action.payload.phone,
      }
    case actions.FORM_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        step: 'code',
        code: action.payload.code,
      }
    default:
      throw new Error()
  }
}

export { actions, initialState, reducer }
