import React from 'react'
import styled from 'styled-components'
import { Text } from '@resultadosdigitais/tangram-components'

const Container = styled.div`
  margin-bottom: var(--size-spacing-05);
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`

function FormControl({ children, errorMessage, disabled }) {
  return (
    <Container errorMessage={errorMessage} disabled={disabled}>
      {React.Children.map(children, child =>
        child ? React.cloneElement(child, { errorMessage, disabled }) : child,
      )}

      {errorMessage && (
        <Text token={Text.tokens.TEXT_XS_BOLD} textColor="--color-red-50">
          {errorMessage}
        </Text>
      )}
    </Container>
  )
}

export default FormControl
