import axios from 'axios'
import { RDSMConversionAPIURL } from './../config/constants'

class RDSMAPI {
  constructor(token) {
    this.token = token
  }

  createConversion(identifier, params) {
    return axios.post(RDSMConversionAPIURL, {
      identificador: identifier,
      token_rdstation: this.token,
      ...params,
    })
  }
}

export default RDSMAPI
