import { actions } from 'state'
import RDSMAPI from './../../lib/rdstation-api'
import { cUtmzFromUTMs } from './../utm'
import {
  RDSMToken,
  RDSMTokenIntl,
  ConversionIdentifier,
} from './../../config/constants'
import i18nConfig from './../../config/i18n.js'

function generateCode({ email, phone, message }) {
  return `<script>window.rwbp={email:'${email}',phone:'${phone}',message:'${message}',lang:'${i18nConfig.lng}'}</script><script defer async src='https://duz4dqsaqembt.cloudfront.net/client/whats.js'></script>`
}

const formSubmit = data => dispatch => {
  const { name, email, phone, message } = data

  const defaultParams = {
    name,
    email,
    personal_phone: phone,
    '[Botão WhatsApp] Mensagem': message,
  }

  const sendConversion = params => {
    let token = RDSMToken
    if (i18nConfig.lng !== 'pt-BR') {
      token = RDSMTokenIntl
    }

    new RDSMAPI(token).createConversion(ConversionIdentifier, {
      ...defaultParams,
      ...params,
    })
  }

  const utm = new window.UniversalUtmTracker()
  if (utm) {
    utm.then(utmObject => {
      sendConversion({
        c_utmz: cUtmzFromUTMs({
          utm_source: utmObject.utm.source,
          utm_medium: utmObject.utm.medium,
          utm_campaign: utmObject.utm.campaign,
          utm_content: utmObject.utm.content,
          utm_term: utmObject.utm.term,
        }),
      })
    }, sendConversion)
  } else {
    sendConversion()
  }
  dispatch({ type: actions.FORM_SUBMITTED, payload: data })
  dispatch({
    type: actions.FORM_SUBMISSION_SUCCESS,
    payload: { code: generateCode(data) },
  })
}

export { formSubmit }
