import esI18n from './locales/es.js'
import ptBrI18n from './locales/pt-BR.js'

export default {
  resources: {
    es: esI18n,
    'pt-BR': ptBrI18n,
  },
  lng: (function () {
    const validLangs = ['es', 'pt-BR']
    const lang = new URLSearchParams(window.location.search).get('locale')

    if (validLangs.includes(lang)) {
      return lang
    }
    return 'pt-BR'
  })(),
  interpolation: {
    escapeValue: false,
  },
}
