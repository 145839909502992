export default {
  translation: {
    'Venha crescer com o RD Station Marketing ++':
      'Venha crescer com o RD Station Marketing, a ferramenta de automação de marketing nº 1 do Brasil.',
    'Gerencie as pessoas (Leads) que entrarem em contato com você ++':
      'Gerencie as pessoas (Leads) que entrarem em contato com você, selecionando inclusive as oportunidades de negócio.',
    'Landing Pages': 'Langing Pages',
    'Crie Landing Pages personalizadas e ampliar as formas ++':
      'Crie Landing Pages personalizadas e ampliar as formas de gerar leads para sua empresa.',
    'Email Marketing': 'Email Marketing',
    'Construa um relacionamento com estes Leads através ++':
      'Construa um relacionamento com estes Leads através de campanhas de email automatizadas.',
  },
}
