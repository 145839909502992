import { useReducer, useMemo } from 'react'

function wrapAsync(dispatch) {
  return action => {
    if (action instanceof Function) {
      return action(dispatch)
    }
    return dispatch(action)
  }
}

export default function useAsyncReducer(reducer, initialState) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch])

  return [state, asyncDispatch]
}
