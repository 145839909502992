import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Theme, Reset } from '@resultadosdigitais/tangram-components'
import styled from 'styled-components'
import {
  Text,
  RDStationMarketing,
} from '@resultadosdigitais/tangram-components'
import { LongArrowDown } from '@resultadosdigitais/tangram-react-icons'
import { useTranslation } from 'react-i18next'

import Form from './Form'
import './../base.css'
import i18nConfig from './../config/i18n.js'
import preview from 'preview.png'
import previewEs from 'preview-es.png'
import Code from './Code'
import Promotion from './Promotion'
import { initialState, reducer } from 'state'
import { formSubmit } from './../services/App/action-creators'
import useAsyncReducer from './../lib/async-dispatch'
import executerWhen from './../lib/executer'

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: var(--size-spacing-04);
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm);
`

const Card = styled.div`
  padding: var(--size-spacing-06);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-20);
  border-radius: var(--border-radius-rounded);
  box-shadow: var(--shadow-sm);
  max-width: 518px;
`

const Base = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
`

const Body = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`

const LeftPanel = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: var(--size-spacing-07);
`

const RightPanel = styled.div`
  flex: 9;
  background-color: var(--color-gray-30);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    margin: 0;
  }

  > * + * {
    margin-top: var(--size-spacing-03);
  }

  @media only screen and (max-width: 1000px) {
    & {
      display: none;
    }
  }
`

const PromotionTransition = styled(Promotion)`
  opacity: 0;
  transform: translateY(100%);

  &.enter {
    opacity: 0;
    transform: translateY(100%);
  }
  &.enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 400ms, transform 400ms;
  }
  &.enter-done {
    opacity: 1;
    transform: translateY(0);
  }
  &.exit {
    opacity: 1;
    transform: translateY(0);
  }
  &.exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 400ms, transform 400ms;
  }
  &.exit-done {
    opacity: 0;
    transform: translateY(100%);
  }
`

const PreviewImage = styled.img`
  max-width: 660px;
`

function App() {
  const [state, dispatch] = useAsyncReducer(reducer, initialState)
  const [showCopySuccess, setShowCopySuccess] = useState(false)
  const [paramsSubmitted, setParamsSubmitted] = useState(false)
  const params = new URLSearchParams(window.location.search)
  const { t } = useTranslation()

  if (params.get('email') && !paramsSubmitted) {
    const RETRY = 500
    state.loading = true
    const data = {
      name: params.get('name'),
      email: params.get('email'),
      message: params.get('message'),
      phone: params.get('phone'),
    }
    executerWhen(
      () => dispatch(formSubmit(data)),
      () => window.UniversalUtmTracker,
      (i, w) => i === 10 || w,
      RETRY,
    )
    setParamsSubmitted(true)
  }

  const previewImage = (() => {
    if (i18nConfig.lng === 'es') {
      return previewEs
    } else {
      return preview
    }
  })()

  return (
    <Theme value={Theme.kinds.lina} style={{ height: '100%' }}>
      <Reset />
      <Base>
        <Header>
          <RDStationMarketing />
        </Header>
        <Body>
          <>
            {state.step === 'code' && (
              <LeftPanel>
                <Card>
                  <Code
                    code={state.code}
                    showCopySuccess={showCopySuccess}
                    onCopy={() => setShowCopySuccess(true)}
                  />
                </Card>
                <CSSTransition in={showCopySuccess} timeout={400}>
                  <PromotionTransition />
                </CSSTransition>
              </LeftPanel>
            )}
            {state.step === 'form' && (
              <>
                <LeftPanel>
                  <Card>
                    <Form
                      onSubmit={data => {
                        dispatch(formSubmit(data))
                      }}
                      loading={state.loading}
                    />
                  </Card>
                </LeftPanel>
                <RightPanel>
                  <Text token={Text.tokens.TEXT_MD_REGULAR}>
                    {t(
                      'É assim que seus clientes irão ver o botão de WhatsApp!',
                    )}
                  </Text>
                  <LongArrowDown title={t('Seta para baixo')} />
                  <PreviewImage src={previewImage} alt="preview" />
                </RightPanel>
              </>
            )}
          </>
        </Body>
      </Base>
    </Theme>
  )
}

export default App
