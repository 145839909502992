import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  Button,
  Form,
  Input,
  Link,
  Spinner,
  Text,
} from '@resultadosdigitais/tangram-components'
import FormControl from './FormControl'
import InputLabel from './InputLabel'
import { useTranslation } from 'react-i18next'
import trackEvent from './../lib/analytics/trackEvent.js'
import getUserCountry from './../services/get-user-country.js'

const FormHeader = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  margin-bottom: var(--size-spacing-06);
`

const StepIndicator = styled(Text)`
  margin-left: auto;
  opacity: 0.5;
`

const GlobalStyle = createGlobalStyle`
  .intl-phone-input {
    width: 100% !important;
    background: var(--color-white) !important;
    border: 1px solid var(--color-gray-60) !important;
    border-radius: var(--border-radius-rounded) !important;
  }

  .react-tel-input .flag-dropdown {
    background: var(--color-white) !important;
    border: 1px solid var(--color-gray-60) !important;
    border-radius: var(--border-radius-rounded) !important;
  }
`

const PrivacyDisclaimer = styled(Text)`
  margin-top: var(--size-spacing-02);
  text-align: center;
  a {
    font: var(--${props => props.token});
  }
`

export default function ({ onSubmit: originalOnSubmit, loading }) {
  const { register, handleSubmit, errors, control } = useForm()
  const { t } = useTranslation()
  const onSubmit = data => {
    trackEvent('WhatsFree: Create Button Clicked', data)
    originalOnSubmit(data)
  }
  return (
    <>
      <GlobalStyle />
      <FormHeader>
        <Text token={Text.tokens.TEXT_LG_BOLD}>
          {t('Vamos criar seu botão')}
        </Text>
        <StepIndicator token={Text.tokens.TEXT_SM_REGULAR}>
          {t('Passo 1/2')}
        </StepIndicator>
      </FormHeader>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormControl
          errorMessage={errors.message && errors.name.message}
          disabled={loading}
        >
          <InputLabel htmlFor="message">
            {t('Qual mensagem mostrar no topo para seus clientes?')}
          </InputLabel>
          <Input
            type="text"
            id="message"
            name="message"
            readOnly={loading}
            placeholder={t(
              'Olá, me conta quem é você para iniciar a conversa!',
            )}
            ref={register()}
          />
        </FormControl>
        <FormControl
          errorMessage={errors.phone && errors.phone.message}
          disabled={loading}
        >
          <InputLabel htmlFor="phone">
            {t('Qual número de WhatsApp você quer usar?')}
          </InputLabel>
          <Controller
            control={control}
            name="phone"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty },
            ) => (
              <PhoneInput
                country={getUserCountry()}
                value={value}
                onChange={phone => onChange(phone)}
                inputClass={'intl-phone-input'}
              />
            )}
          />
        </FormControl>
        <FormControl
          disabled={loading}
          errorMessage={errors.email && errors.email.message}
        >
          <InputLabel htmlFor="email">
            {t('Em qual email você quer receber os contatos do Whatsapp?')}
          </InputLabel>
          <Input
            type="email"
            id="email"
            name="email"
            autoComplete="username"
            readOnly={loading}
            ref={register({
              required: t('Este campo não pode ficar em branco'),
              pattern: {
                value: /^(([^<>()[\]\\.'~^,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t('Email inválido'),
              },
            })}
          />
        </FormControl>
        <FormControl
          disabled={loading}
          errorMessage={errors.name && errors.name.message}
        >
          <InputLabel htmlFor="name">{t('Qual seu nome?')}</InputLabel>
          <Input
            type="text"
            id="name"
            name="name"
            readOnly={loading}
            ref={register({
              required: t('Este campo não pode ficar em branco'),
            })}
          />
        </FormControl>
        <Button type="submit" style={{ width: '100%' }}>
          {loading && <Spinner color="var(--color-white)" />}
          {!loading && t('Criar Botão de WhatsApp')}
        </Button>
        <PrivacyDisclaimer token={Text.tokens.TEXT_XS_REGULAR}>
          {t('Ao criar o seu botão você concorda com a') + ' '}
          <Link href="https://resultadosdigitais.com.br/politica-de-privacidade/">
            {t('política de privacidade')}
          </Link>
        </PrivacyDisclaimer>
      </Form>
    </>
  )
}
