import i18nConfig from './../../config/i18n.js'

const addIntlTag = message => {
  if (i18nConfig.lng === 'pt-BR') {
    return message
  }

  return `[INTL] (${i18nConfig.lng}) ${message}`
}

export default (message, data = null, analytics = null) => {
  if (analytics === null) {
    analytics = window.analytics
  }

  analytics.track(addIntlTag(message), data)
}
