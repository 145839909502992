import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import reportWebVitals from './services/reportWebVitals'
import i18nConfig from './config/i18n.js'
import './lib/analytics'
import './lib/hotjar'

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

i18next.use(initReactI18next).init(i18nConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
