export default {
  translation: {
    'É assim que seus clientes irão ver o botão de WhatsApp!':
      '¡Así es como tus clientes verán el botón de WhatsApp!',
    'Seta para baixo': 'flecha hacia abajo',
    'Está quase pronto!': '¡Está casi listo!',
    'Prontinho, botão criado! 🎉': '¡Listo, el botón está creado! 🎉',
    'Inclua este código em seu site:': 'Incluya este código en su sitio web:',
    'Agora você só precisa instalar o código no seu site!':
      '¡Ahora solo necesitas instalar el código en tu sitio web!',
    'Copie e cole este código antes da tag </body>;':
      'Copia y pega este código antes de la etiqueta </body>;',
    'Copiar ': 'Copiar ',
    'Código copiado!': '¡Código copiado!',
    'Utiliza alguma dessas ferramentas? Confira o passo a passo!':
      '¿Usas alguna de estas herramientas? Revisa los pasos de cómo hacerlo.',
    'Outras plataformas': 'Otras plataformas',
    'Vamos criar seu botão': 'Creemos tu botón',
    'Passo 1/2': 'Paso 1/2',
    'Passo 2/2': 'Paso 2/2',
    'Qual mensagem mostrar no topo para seus clientes?':
      '¿Qué mensaje inicial deseas mostrar a tus clientes?',
    'Olá, me conta quem é você para iniciar a conversa!':
      '¡Hola! Dime las informaciónes abajo para iniciar una conversación',
    'Qual número de WhatsApp você quer usar?':
      '¿Qué número de WhatsApp quieres usar?',
    'Em qual email você quer receber os contatos do Whatsapp?':
      '¿En qué correo quieres recibir los contactos de WhatsApp que generarás?',
    'Este campo não pode ficar em branco': 'Este campo no puede estar vacío.',
    'Email inválido': 'Email inválido',
    'Qual seu nome?': '¿Cuál es tu nombre?',
    'Criar Botão de WhatsApp': 'Crear botón de WhatsApp',
    'Ao criar o seu botão você concorda com a':
      'Al crear tu botón estás aceptando las',
    'política de privacidade': 'políticas de privacidad',
    'Que tal turbinar ainda mais seu Marketing?':
      '¿Qué tal impulsar aún más tu marketing?',
    'Venha crescer com o RD Station Marketing ++':
      'Ven a crecer con RD Station Marketing, la herramienta de automatización de marketing número uno en América Latina.',
    'Gestão de Leads': 'Manejo de Leads',
    'Gerencie as pessoas (Leads) que entrarem em contato com você ++':
      'Gestiona los leads que se ponen en contacto contigo e selecciona las oportunidades comerciales.',
    'Landing Pages': 'Langing Pages',
    'Crie Landing Pages personalizadas e ampliar as formas ++':
      'Crea Landing Pages personalizadas y aumenta las formas de generar prospectos para tu negocio.',
    'Email Marketing': 'Email Marketing',
    'Construa um relacionamento com estes Leads através ++':
      'Establece una relación con tus clientes potenciales a través de campañas de email personalizadas.',
    'Testar grátis por 10 dias': 'Pruébalo grátis por 10 días',
  },
}
