import styled from 'styled-components'

const InputLabel = styled.label`
  font-weight: var(--text-weight-semibold);
  color: var(--color-gray-90);
  display: block;
  padding-bottom: var(--size-spacing-03);
`

export default InputLabel
