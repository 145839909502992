import React, { useRef } from 'react'
import styled from 'styled-components'
import { Text, Button, Card } from '@resultadosdigitais/tangram-components'
import { useTranslation } from 'react-i18next'

import trackEvent from './../lib/analytics/trackEvent.js'
import logoWix from 'logo-wix.png'
import logoWordpress from 'logo-wordpress.png'
import i18nConfig from './../config/i18n.js'

const CodeHeader = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin-bottom: 0;
  }

  margin-bottom: var(--size-spacing-06);
`

const StepIndicator = styled(Text)`
  margin-left: auto;
  opacity: 0.5;
`

const Code = styled.textarea`
  width: 100%;
  border-radius: var(--border-radius-rounded);
  border: 1px solid #19c1ce;
  padding: var(--size-spacing-04);
  font-family: monospace;
  overflow: scroll;
  max-height: 30vh;
`

const CardCluster = styled.div`
  overflow: hidden;

  > * {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    margin: calc(${props => props.spacing} / 2 * -1);
    flex-wrap: wrap;
  }

  > * > * {
    margin: calc(${props => props.spacing} / 2);
    max-width: 15ch;
  }
`

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--size-spacing-03) var(--size-spacing-05);

  > p {
    margin: 0;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: ${props => props.spacing};
  }
`

const HelpAndButton = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin-bottom: 0;
    margin-right: var(--size-spacing-02);
    font-style: italic;
    opacity: 0.5;
  }

  > button {
    margin-left: auto;
  }
`

const LightText = styled(Text)`
  font-style: italic;
  opacity: 0.5;
`

const SuccessCopyButton = styled(Button)`
  color: var(--color-green-50);
  border-color: var(--color-green-50);
`

export default function ({ code, showCopySuccess, onCopy }) {
  const textAreaRef = useRef(null)
  const { t } = useTranslation()

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand('copy')
    onCopy()
  }

  const textAreaOnClick = () => {
    trackEvent('WhatsFree: Code Box Clicked')
  }

  const copyButtonOnClick = e => {
    copyToClipboard(e)
    e.target.focus()
    trackEvent('WhatsFree: Copy Code Clicked')
  }

  const integrationsVideosUrls = (() => {
    if (i18nConfig.lng === 'es') {
      return {
        wordpress: 'https://www.youtube.com/watch?v=St0awEo39MI',
        wix: 'https://www.youtube.com/watch?v=oIoV8oCnsZU',
        other: 'https://www.youtube.com/watch?v=sHbPF92UA-I',
      }
    } else {
      return {
        wordpress:
          'https://www.loom.com/share/e5c5486b514c48c1bfb7689f9d7b2a18',
        wix: 'https://www.loom.com/share/87941ec6d7a346909eaae0eb4a6138b2',
        other: 'https://www.loom.com/share/e3ccf31027694ea78964dfe42604bb4f',
      }
    }
  })()

  return (
    <Stack spacing="var(--size-spacing-04)">
      <CodeHeader>
        <Text token={Text.tokens.TEXT_LG_BOLD}>
          {!showCopySuccess && t('Está quase pronto!')}
          {showCopySuccess && t('Prontinho, botão criado! 🎉')}
        </Text>
        <StepIndicator token={Text.tokens.TEXT_SM_BOLD}>
          {t('Passo 2/2')}
        </StepIndicator>
      </CodeHeader>
      <Stack spacing="var(--size-spacing-02)">
        <Stack spacing="var(--size-spacing-01)">
          <Text token={Text.tokens.TEXT_MD_REGULAR}>
            {!showCopySuccess && t('Inclua este código em seu site:')}
            {showCopySuccess &&
              t('Agora você só precisa instalar o código no seu site!')}
          </Text>
          <LightText token={Text.tokens.TEXT_SM_REGULAR}>
            {t('Copie e cole este código antes da tag </body>;')}
          </LightText>
        </Stack>
        <div>
          <Code
            ref={textAreaRef}
            value={code}
            onClick={textAreaOnClick}
            readOnly
          />
          <HelpAndButton>
            {document.queryCommandSupported('copy') && (
              <>
                {!showCopySuccess && (
                  <Button
                    kind={Button.kinds.secondary}
                    onClick={copyButtonOnClick}
                  >
                    {t('Copiar ')}
                  </Button>
                )}
                {showCopySuccess && (
                  <SuccessCopyButton
                    kind={Button.kinds.secondary}
                    onClick={copyButtonOnClick}
                  >
                    {t('Código copiado!')}
                  </SuccessCopyButton>
                )}
              </>
            )}
          </HelpAndButton>
        </div>
      </Stack>
      <div>
        <LightText token={Text.tokens.TEXT_SM_REGULAR}>
          {t('Utiliza alguma dessas ferramentas? Confira o passo a passo!')}
        </LightText>
        <CardCluster spacing="var(--size-spacing-04)">
          <div>
            <StyledCard
              clickable
              onClick={() => {
                trackEvent('WhatsFree: Help Card Clicked', {
                  option: 'wordpress',
                })
                window.open(integrationsVideosUrls.wordpress)
              }}
            >
              <img src={logoWordpress} alt="Wordpress" height="25" />
            </StyledCard>
            <StyledCard
              clickable
              onClick={() => {
                trackEvent('WhatsFree: Help Card Clicked', {
                  option: 'wix',
                })
                window.open(integrationsVideosUrls.wix)
              }}
            >
              <img src={logoWix} alt="Wix" height="25" />
            </StyledCard>
            <StyledCard
              clickable
              onClick={() => {
                trackEvent('WhatsFree: Help Card Clicked', {
                  option: 'other',
                })
                window.open(integrationsVideosUrls.other)
              }}
            >
              <Text
                token={Text.tokens.TEXT_SM_BOLD}
                style={{ color: 'var(--color-primary-70)' }}
              >
                {t('Outras plataformas')}
              </Text>
            </StyledCard>
          </div>
        </CardCluster>
      </div>
    </Stack>
  )
}
