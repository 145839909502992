const RDSMSignupURL = process.env.REACT_APP_RDSM_SIGNUP_URL
const RDSMToken = process.env.REACT_APP_RDSM_TOKEN
const RDSMTokenIntl = process.env.REACT_APP_RDSM_TOKEN_INTL
const ConversionIdentifier = process.env.REACT_APP_CONVERSION_IDENTIFIER
const RDSMConversionAPIURL = process.env.REACT_APP_RDSM_CONVERSION_API_URL

export {
  RDSMSignupURL,
  RDSMToken,
  RDSMTokenIntl,
  ConversionIdentifier,
  RDSMConversionAPIURL,
}
