const cUtmzFromUTMs = ({
  utm_source,
  utm_medium,
  utm_campaign,
  utm_content,
  utm_term,
}) => {
  return `00000000.0000000000.00.00.utmcsr=${utm_source}|utmccn=${utm_campaign}|utmcmd=${utm_medium}|utmcct=${utm_content}|utmctr=${utm_term}`
}

export { cUtmzFromUTMs }
