import styled from 'styled-components'
import { Button } from '@resultadosdigitais/tangram-components'
import { RDSMSignupURL } from './../../config/constants'
import i18nConfig from './../../config/i18n.js'
import { useTranslation } from 'react-i18next'
import { cUtmzFromUTMs } from './../../services/utm'
import trackEvent from '../../lib/analytics/trackEvent.js'

function getUrlParameter(param) {
  return new URLSearchParams(window.location.search).get(param) || ''
}

const StyledButton = styled(Button)`
  color: var(--color-gray-100);
  background: var(--color-white);
`

export default ({ email }) => {
  const { t } = useTranslation()

  const onClick = () => {
    trackEvent('WhatsFree: Free Trial Panel Button Clicked')
  }

  const current_utms = {
    utm_source: getUrlParameter('utm_source'),
    utm_medium: getUrlParameter('utm_medium'),
    utm_campaign: getUrlParameter('utm_campaign'),
    utm_content: getUrlParameter('utm_content'),
    utm_term: getUrlParameter('utm_term'),
  }

  const addCurrentUTMsInUrl = url => {
    return `${url}&utm_source=${current_utms.utm_source}&utm_medium=${current_utms.utm_medium}&utm_campaign=${current_utms.utm_campaign}&utm_content=${current_utms.utm_content}&utm_term=${current_utms.utm_term}`
  }

  const signUpUrl = (() => {
    if (i18nConfig.lng === 'pt-BR') {
      return RDSMSignupURL
    }

    return addCurrentUTMsInUrl(`${RDSMSignupURL}?locale=${i18nConfig.lng}`)
  })()

  return (
    <>
      <form action={signUpUrl} method="POST">
        <input type="hidden" name="email" value={email} />
        <input type="hidden" name="_ga" value={getUrlParameter('_ga')} />
        <input
          type="hidden"
          name="c_utmz"
          value={cUtmzFromUTMs(current_utms)}
        />
        <StyledButton
          kind={Button.kinds.secondary}
          type="submit"
          onClick={onClick}
        >
          {t('Testar grátis por 10 dias')}
        </StyledButton>
      </form>
    </>
  )
}
